/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@font-face {
  font-family: 'Tungsten';
  src: local('Tungsten'), url(./assets/fonts/Tungsten/Tungsten-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Aktiv Grotesk';
  src: local('Aktiv Grotesk'), url(./assets/fonts/Aktiv_Grotesk/AktivGrotesk-Light.otf) format('truetype');
}

:root {
  --font-family: 'Aktiv Grostesk', Helvetica, sans-serif;
  --font-body: 'Rubik', Helvetica, sans-serif;
  --font-body-2: 'Aktiv Grostesk', Helvetica, sans-serif;
  --font-intro: 'Bebas Neue', Helvetica, sans-serif;
  --font-blog-title: 'Rubik', Helvetica, sans-serif;

  --gradient-text:  linear-gradient(90deg, #9EDC3D -1.04%, #1BC5EB 101.01%);;
  --gradient-bar: linear-gradient(to right,  #f4953a , #dfad44);

 
  
  --solid-text: #f4f4f7;
  --solid-text-2: #f4f4f7;
  --solid-text-3: #918f8f;
  --color-bg: #121517;
  --color-accent: #f4f4f7;
  --color-accent-2: #181C20;
  --color-accent-3: #666666;
  --color-footer: #181C20;
  --color-blog: #042c54;
  --color-text: #f4f4f7;
  --color-text-2: #bebebe;
  --color-subtext: #f4f4f7;
  --color-general: #f4f4f7;
  --color-links: #9edc3e;

  --border-radius: 0px;
  --letter-spacing: 2px;

  /* Min: 100, Max: 150 */
  /* --h1: clamp(6.25rem, 3.12497rem + 9.091vw, 9.375rem); */
  --h1: 4.375rem;
  --h1-proj: clamp(6.25rem, 5.92275rem + 9.091vw, 6.875rem);
  /* Min: 46, Max: 46 */
  --h2: 3.75rem;
  /* Min: 36, Max: 36 */
  --h3: 2.25rem;
  --h3-lh: clamp(2.5rem, 2.5vw, 2rem);
  /* Min: 32, Max: 32 */
  --h4: clamp(2rem, 2rem + 0vw, 2rem);
  /* Min: 20, Max: 20 */
  --h5: clamp(1.25rem, 1.25rem + 0vw, 1.25rem);
  /* Min: 14, Max: 14 */
  --h6: clamp(0.875rem, 0.875rem + 0vw, 0.875rem);
  /* Min: 16, Max: 18 */
  --p: clamp(1rem, 0.87488rem + 0.364vw, 1.125rem);

}

/* 
colours to remember
orange: #f4953a 
black: #231F20
new black: #181C20
ghost white: #f4f4f7
white: #fff
blue: #00BCD4
Golen Yellow: #dfad44
*/