
.site__social-heading{
    width: 100%;
    color: var( --solid-text);
}
.site__social-heading h1{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: clamp(3.5rem, 3vw, 3rem);
    line-height: clamp(3.5rem, 3.5vw, 3rem);
    letter-spacing: 0.02rem;
}
/* .site__social-heading svg{
    color: white;
} */
/* .site__social-container{
    display: flex;
    flex-direction: row;
} */
.site__social-container_groupA{
    flex: 1;
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;
    justify-content: space-around;
    border-radius: var(--border-radius);
    padding-bottom: 2rem;
}
/* .site__social-container_groupA h3{
    font-family: var(--font-blog-title);
    font-weight: 400;
    font-size: clamp(1.5rem, 2vw, 2rem);
    line-height: clamp(1.5rem, 2.5vw, 2rem);
    color: var(--color-text);
    letter-spacing: 0.02rem;
} */
.site__social-container_groupA p{
    font-family: var(--font-body-2);
    font-weight: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 15px;
    padding-bottom: 1rem;
    letter-spacing: var(--letter-spacing);
    text-transform: uppercase;
    color: var(--color-text-2);
    box-sizing: border-box;
}

.site__social-container_groupB{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}
.site__social-container_groupC{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-top: 2rem;
}

.site__social.section__padding{
    padding: 6rem 6rem 4rem 6rem;
}


/* @media screen and (max-width: 1050px){
    
    .site__social-heading{
        margin: 0;
    }
} */

@media screen and (max-width: 990px){
    .site__social-container{
        flex-direction: column;
    }
    
    .site__social-container_groupA{
        margin: 2rem 0;
    }
    .site__social-container_groupA .site__social-container_article{
        width: 48%;
    }
    .site__social-container_groupA .site__social-container_article-image{
        height: 250px;
    }
}
@media screen and (max-width: 850px){
    .site__social-container_groupB{
        grid-template-columns: repeat(2,1fr);
    }
    
    .site__social-container_groupA .site__social-container_article{
        width: 100%;
    }

}
@media screen and (max-width: 550px){
    .site__social-container_groupB{
        grid-template-columns: repeat(1,1fr);
    }
    .site__social.section__padding{
        padding: 2rem 2rem;
    }
    .site__social-heading h1{
        font-size: clamp(2rem, 2vw, 2rem);
        line-height: clamp(2rem, 2.5vw, 2rem);
    }

}