.site__gallery-container_photo{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}
.site__gallery-container_photo-image p{
    color:var(--color-text-2);
    font-family: var(--font-body-2);
    font-weight: 400;
    font-size: 10px;
    padding-bottom: .2rem;
}
.site__gallery-container_photo-image{
    width: 100%;
}
.site__gallery-container_photo-image img{
    width: 100%;
    border-radius: var(--border-radius);
    filter: brightness(100%);

}
.site__gallery-container_photo-image img:hover{
    filter: brightness(70%);
    transform: scale(1.01);
}
.site__gallery-container_photo-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .5rem 0 0 0;
    /* position: absolute; */
    width: 100%;
}

.site__gallery-container_photo-content p{
    font-family: var(--font-body-2);
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    padding-bottom: 2rem;
    letter-spacing: .1rem;
    text-transform: uppercase;
    color: var(--color-text-2);
    box-sizing: border-box;

}
.site__gallery-container_photo-content h5{
    font-family: var(--font-blog-title);
    font-weight: 400;
    font-size: var(--h5);
    line-height: clamp(1.5rem, 2.5vw, 2rem);
    color: var(--color-text);
    letter-spacing: 0.02rem;
    /*  padding-bottom: 1rem; */
}

.links-photo:hover{
    color: var(--color-links);
}

@media screen and (max-width: 850px){
    .site__gallery-container_photo-content h3{
        line-height: 44px;
        font-size: 38px;
    }
    .site__gallery-container_photo-content p{
        font-size: 14px;
        padding-bottom: 1rem;
        line-height: 34px;
    }
    .site__gallery-container_photo{
        filter: unset;
    }
}

@media screen and (max-width: 550px){
    .site__gallery-container_photo-content{
        padding: 1rem 0.5rem;
    }
    .site__gallery-container_photo-content h3{
        font-size: 24px;
        line-height: 28px;
    }
    .site__gallery-container_photo-content p{
        font-size: 14px;
        padding-bottom: 0.5rem;
    }

}