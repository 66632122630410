

.site__about {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    border-radius: var( --border-radius);
    border: rgba(37, 43, 50, 1) solid 1px;
    /* background: var(--color-bg); */
    /* background: var(--color-footer);

      /* ff 3.6+ */
      background:-moz-radial-gradient(ellipse at 98% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);

      /* safari 5.1+,chrome 10+ */
      background:-webkit-radial-gradient(ellipse at 98% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);
  
      /* opera 11.10+ */
      background:-o-radial-gradient(ellipse at 98% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);
  
      /* ie 10+ */
      background:-ms-radial-gradient(ellipse at 98% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);
  
      /* global 92%+ browsers support */
      background:radial-gradient(ellipse at 98% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);
}

.site__about-feature {
    display: flex;
}


/* Customizing feature component as per needs */
.site__about-feature .site__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.site__about-feature .site__features-container__feature-text {
    max-width: 700px;
    
}

.site__about-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.site__about-heading h3 {
    font-size: var(--h3);
    line-height: clamp(2.5rem, 2.5vw, 2rem);
    font-weight: 300;
    font-family: var(--font-family);
    max-width: 510px;
    color: white;
}

.site__about-heading p {
    font-family: var(--font-body-2);
    font-weight: 300;
    font-size: var(--text-p);
    line-height: 30px;
    /* color: var(--color-subtext); */
    color: white;
    cursor: pointer;
}


.site__about-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.site__about-container .site__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.site__about-container .site__features-container__feature-text {
    margin-top: 0.5rem;
}

/* .site__about.section__margin{
    margin: 4rem 6rem 0 6rem;
}  */

.triangle{
  content:'';
  display: block;
  position: absolute;
  left: 14rem;
  right: 0;
  width: 0;
  height: 0;
  border-top: 46px solid #231F20;
  border-right: 100px solid transparent;
}

@media screen and (max-width: 850px) {
    .site__about-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .site__about-heading p {
        margin-top: 1rem;
    }
    .site__about-container {
         flex-wrap: wrap;
    }
    .site__about.section__margin{
        padding: 2rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .site__about-feature .site__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .site__about-feature .site__about-container_feature-text {
        margin-top: 0.5rem;
    }
    .about__bg{
        padding: .2rem;
    }
    .triangle{
        left: 5rem;
    }
    .site__about-container .site__features-container__feature{
        margin-left: 0;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .site__about-container .site__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
    
}