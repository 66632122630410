.site__card-container_card{
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: var( --border-radius);
    border: rgba(37, 43, 50, 1) solid 1px;
     /* ff 3.6+ */
     background:-moz-radial-gradient(ellipse at 98% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);

     /* safari 5.1+,chrome 10+ */
     background:-webkit-radial-gradient(ellipse at 98% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);
 
     /* opera 11.10+ */
     background:-o-radial-gradient(ellipse at 98% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);
 
     /* ie 10+ */
     background:-ms-radial-gradient(ellipse at 98% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);
 
     /* global 92%+ browsers support */
     background:radial-gradient(ellipse at 98% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);
  
    
}
.site__card-container_card:hover{
    transform: scale(1.01);
    /* border: var(--color-links) solid 1px; */
     /* ff 3.6+ */
     background:-moz-radial-gradient(ellipse at 98% 0%, rgb(168, 27, 146) 0%, rgb(101, 46, 102) 63%);

     /* safari 5.1+,chrome 10+ */
     background:-webkit-radial-gradient(ellipse at 98% 0%, rgb(168, 27, 146) 0%, rgb(101, 46, 102) 63%);
 
     /* opera 11.10+ */
     background:-o-radial-gradient(ellipse at 98% 0%, rgb(168, 27, 146) 0%, rgb(101, 46, 102) 63%);
 
     /* ie 10+ */
     background:-ms-radial-gradient(ellipse at 98% 0%, rgb(168, 27, 146) 0%, rgb(101, 46, 102) 63%);
 
     /* global 92%+ browsers support */
     background:radial-gradient(ellipse at 98% 0%, rgb(168, 27, 146) 0%, rgb(101, 46, 102) 63%);
}
.site__card-container_card-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem; 
    position: relative;
    

}
.site__card-container_card-content p{
    font-family: var(--font-body-2);
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    padding-bottom: .5rem;
    letter-spacing: .1rem;
    text-transform: uppercase;
    color: var(--color-text-2);
    box-sizing: border-box;

}
.site__card-container_card-content h3{
    font-family: var(--font-blog-title);
    font-weight: 400;
    font-size: clamp(1rem, 2vw, 2rem);
    line-height: clamp(1rem, 2.5vw, 2rem);
    color: var(--color-text);
    letter-spacing: 0.02rem;
    padding-bottom: 1rem;
}


@media screen and (max-width: 850px){
    .site__card-container_card-content h3{
        /* font-size: 18px; */
        line-height: 44px;
        font-size: 38px;
    }
    .site__card-container_card-content{
        width: 70%;
    }
    .site__card-container_card-content p{
        font-size: 20px;
        padding-bottom: 1rem;
        line-height: 34px;
    }
}

@media screen and (max-width: 550px){
    .site__card-container_card-content{
        padding: 1.5rem 1rem;
        width: 100%;
    }
    .site__card-container_card-content h3{
        font-size: 24px;
        line-height: 28px;
        /* padding-left: 1rem; */
    }
    .site__card-container_card-content p{
        line-height: 20px;
        font-size: 14px;
        /* padding-left: 1rem; */
        padding-bottom: 0.5rem;
    }

}