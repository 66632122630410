.site__features-container__feature{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    margin: 1rem;
}
.site__features-container__feature-title{
    flex: 1;
    max-width: 180px;
    margin-right: 2rem;
}
.site__features-container__feature-title h5{
    font-family: var(--font-body);
    font-weight: 400;
    font-size: var(--h6);
    line-height: 24px;
    color: var(--solid-text);

}
.site__features-container__feature-title div{
    width: 38px;
    height: 2px;
    background: var(--color-links);
    /* box-shadow: 0 4px 4px rgba(0,0,0,0.25); */
    margin-bottom: 0.5rem;
}
.site__features-container_feature-text{
    flex: 2;
    max-width: 460px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.site__features-container_feature-text h6{
    font-family: var(--font-body-2);
    font-weight: 300;
    font-size: var(--h6);
    line-height: 24px;
    color: var(--solid-text-3);
    margin-top: .5rem;
}

.site__features-container_feature-image {
    display: none;
    width: 25px;
    height: auto;
    margin: 5px 5px 0 0;
}

.site__about-container .site__features-container__feature:nth-child(3) img{
        display: grid;
        flex-direction: row;
}
#scale-up{
    width: 35px;
}
.site__about-container .site__features-container__feature-title h1{
    color: var(--solid-text);
}

@media screen and (max-width: 550px){
    .site__features-container__feature-title h1{
        font-size: 18px;
        line-height: 27px;
    }
    .site__features-container__feature-title p{
        font-size: 12px;
        line-height: 20px;
    }
    .site__features-container__feature{
        margin: 1rem 0;
    }

}
@media screen and (max-width: 550px){
} 
