video::-webkit-media-controls {
    display: none;
  }
  
.site__project h2{
    width: 100%;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: var(--h2);
    line-height: 55px;
    letter-spacing: 0.02rem;
    color: var(--color-subtext);
    padding-bottom: 2rem;
}
.site__project h3{
    width: 100%;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: var(--h3);
    line-height: 55px;
    letter-spacing: 0.02rem;
    color: var(--color-text);
    padding-bottom: 1rem;
}
.site__project h4{
    width: 100%;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 20px;
    line-height: 55px;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    color: var(--color-text-2);
}
.site__project p{
    font-family: var(--font-body-2);
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: var(--color-text-2);
    line-height: 25px;
    padding-bottom: 0.5rem;
}
.site__project strong{
    color: var(--color-text)
}
.site__project-link{
    color: var(--color-links);
}
.site__project-header{
    justify-content: unset;
}
.site__project-header-content{
    flex-direction: column;
    align-items: flex-start;
}
.site__project-header-content h1{
    font-family: var(--font-intro);
    font-size: var(--h1-proj);  
    line-height: 150px;
    padding-bottom: 4rem; 
    font-weight: 300;
}
.site__project-header-imgCollage{
    grid-gap: 0.2rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
.site__project-header-imgCollage-groupA, .site__project-header-imgCollage-groupB, 
.site__project-header-imgCollage-groupD{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.site__project-header-imgCollage-groupA img, .site__project-header-imgCollage-groupB img, 
.site__project-header-imgCollage-groupC img, .site__project-header-imgCollage-groupD img{
    width: 100%;
}
.site__tldr{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}
.site__tldr-groupA{
    flex: 1;
    margin-right: 5rem;
}
.site__tldr-groupB{
    flex: 1;
}
.site__casestudy{
    display: flex;
    flex-wrap: wrap;
}
.site__casestudy-image{
    margin-right: 5rem;
    padding-bottom: 2rem;
}
.site__casestudy-image img{
    max-width: 150px;
    height: auto;
}

.site__casestudy-groupA, .site__casestudy-groupB, .site__casestudy-groupC {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
}
.site__casestudy-groupD{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.site__casestudy-content{
    max-width: 700px;
}
.snapshot-img img{
    width: 100%;
    height: auto;
    /* margin-top: 3rem; */
}
.video__container{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
}
.video{
    width: 100%;
}
#proj1-img1{
    margin-top: unset;
}
.site__UX-Design-content, .site__UI-Design-content{
    padding: 5rem 0;
    max-width: 700px;
}

.site__back-to-top{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
}
.site__back-to-top a{
    padding: .5rem 2rem;
    color: var(--solid-text);
    /* background-color: #7ab61f; */
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    border-radius: 50px;
    border: 2px solid  var(--color-text);
    outline: none;
}
.site__back-to-top a:hover{
    border: 2px solid  var(--color-links);
    color: var(--color-links);

}
/* .project-btn{
    padding: 0 0 4rem 0;
} */
.project-btn a{
    padding: .5rem 1rem;
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    border-radius: 50px;
    outline: none;
    border: 1px solid  var(--color-text);
}
.project-btn a:hover{
    background-color: var(--color-text);
    color: var(--color-bg);
}

/* Hogwarts Legacy Edits */
#HG-title{
    font-size: 100px;
    line-height: 100px;
}
#HG-header-content{
    max-width: unset;
}
#sm-gif{
    width: 40%;
}
#med-gif{
    width: 80%;
}
#project-page-banner{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

/* UI Page only Edits */
.solid__text-UI{
    font-family: var(--font-intro);
    font-size: var(--h1-proj);
    font-weight: 300;
    line-height: 100px;
    padding-bottom: 2rem;
    color: var(--solid-text);
}
#projectTitle-UI{
    margin-right: 2rem;
}
#project-UI{
    flex: 1 1;
    margin-right: 4rem;
}
#wa-UI-title{
    font-size: 74px;
    line-height: 80px;
}

@media screen and (max-width: 1050px){
    .site__project-header-content h1{
            font-size: 120px;
            line-height: 120px;
            /* letter-spacing: -.5rem; */
    }
    .site__3col{
        display: none;
    }
    .site__2col{
        flex-wrap: wrap;
    }
    .site__2col-groupA{
        margin-right: 0;
    }
}
@media screen and (max-width: 490px){
    
    .site__project h3{
        line-height: 35px;
    }
    .site__project-header-content h1{
        font-size: 110px;
        /* letter-spacing: -0.2rem; */
        line-height: 100px;
        padding-bottom: 2rem;
    }
    #wa-UI-title, #md-ui-title, #wa-UI-title2{
        font-size: 80px;
        line-height: 70px;
    }
    .site__project h4{
        line-height: 30px;
    }
    .site__tldr-groupA{
        margin-right: 2rem;
    }
    .site__casestudy-groupA, .site__casestudy-groupB, .site__casestudy-groupC, .site__casestudy-groupD {
        flex-wrap: wrap;
    }

    .site__UX-Design-content, .site__UI-Design-content{
        padding: 2rem 0;
    }
    .site__2col-img{
        flex-direction: column;
    }

    .site__2col-img-groupA{
        margin-right: 0;
    }
    .site__2col-img-groupB{
        grid-gap: 0;
    }
 
    #HG-title{
        font-size: 60px;
        line-height: 70px;
    }
    #project-page-banner{
        width: unset;
    }
    #site__page-ux-remove-mobile{
        display: none;
    }
    #projectTitle-UI{
        padding-bottom: 4rem;
    }
    .site__project-header-imgCollage{
        grid-template-columns: repeat(2, 1fr);
    }
    #projectTitle-mobile-sm{
        font-size: 80px;
        line-height: 70px;
    }
    

}