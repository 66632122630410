
.site__tidbits-heading{
    width: 100%;
    color: var( --solid-text);
}
.site__tidbits-heading h1{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: clamp(2.5rem, 2vw, 2rem);
    line-height: clamp(2.5rem, 2.5vw, 2rem);
    letter-spacing: 0.02rem;
}
.site__tidbits-heading svg{
    color: white;
}
.site__tidbits-container_groupA{
    flex: 1;
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
    padding: 3rem 4rem;
    text-align: center;
}
/* .site__tidbits-container_groupA h3{
    font-family: var(--font-blog-title);
    font-weight: 400;
    font-size: clamp(2.5rem, 2vw, 2rem);
    line-height: clamp(2.5rem, 2.5vw, 2rem);
    color: var(--color-text);
    letter-spacing: 0.02rem;
} */
.site__tidbits-container_groupA p{
    font-family: var(--font-body-2);
    font-weight: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 15px;
    padding-top: 1rem;
    letter-spacing: var(--letter-spacing);
    text-transform: uppercase;
    color: var(--color-text-2);
    box-sizing: border-box;
}

.site__tidbits-container_groupB{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-bottom: 2rem;
}

.site__tidbits-container_groupC{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-top: 2rem;
}


@media screen and (max-width: 1050px){
    
    .site__tidbits-heading{
        margin: 0;
    }
}

@media screen and (max-width: 990px){
    .site__tidbits-container{
        flex-direction: column;
    }
    
    .site__tidbits-container_groupA{
        margin: 2rem 0;
    }
    .site__tidbits-container_groupA .site__tidbits-container_article{
        width: 48%;
    }
    .site__tidbits-container_groupA .site__tidbits-container_article-image{
        height: 250px;
    }
}
@media screen and (max-width: 850px){
    .site__tidbits-container_groupB{
        grid-template-columns: repeat(1,1fr);
    }
   
    
}
@media screen and (max-width: 550px){
   
    .site__tidbits-container_groupA{
        padding: 2rem 0;
        text-align: left;
        align-items: flex-start;
        /* display: block; */
    }

    .site__tidbits-heading h1{
        font-size: 46px;
        line-height: 50px;
    }

    .site__tidbits-container_groupB{
        margin-bottom: unset;
    }
   

}