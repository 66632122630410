.site__brand{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
}
.site__brand div{
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.site__brand img{
    width: 80px;
    height: auto;
    filter: brightness(50%);
}
#paintable{
    width: 60px;
}
#ADAA{
    width: 70px;
}