.site__header-pages{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* align-items: flex-end; */
    /* margin-bottom: 4rem; */
    /* margin-top: 5rem; */
}
.site__header-img-BG-GD-pages{
    /* background-image: linear-gradient(0deg, rgba(18, 21, 23, 1), rgba(18, 21, 23, .5)), url("./../assets/gd-bg.webp");
    background-repeat: no-repeat; */
    height: auto;
    background-size: cover;
  }
  .site__header-img-BG-UXUI-pages{
    /* background-image: linear-gradient(0deg, rgba(18, 21, 23, 1), rgba(18, 21, 23, .5)), url("./../assets/ux-bg.webp");
    background-repeat: no-repeat; */
    height: auto;
    background-size: cover;
    background-position: top;
  }
 
.site__pages h2{
    width: 100%;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 50px;
    line-height: 55px;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    color: var(--color-subtext);
    padding-bottom: 2rem;
}
.site__pages h3{
    width: 100%;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 30px;
    line-height: 55px;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    color: var(--color-text);
    padding-bottom: 1rem;
}
.site__pages h4{
    width: 100%;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 20px;
    line-height: 55px;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    color: var(--color-text-2);
}
.site__pages p{
    font-family: var(--font-body-2);
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: var(--color-text-2);
    line-height: 25px;
    padding-bottom: 0.5rem;
}
.site__pages strong{
    color: var(--color-text)
}
.site__pages-link{
    color: var(--color-links);
}
.site__pages-header{
    justify-content: unset;
}
.site__pages-header-content{
    flex-direction: column;
    align-items: flex-start;
}
.site__pages-header-content h1{
    font-family: helvetica;
    font-size: 220px;  
    line-height: 200px;
    padding-bottom: 4rem; 
}

/* About Me CSS */

.site__aboutMe{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.site__aboutMe-photo h6{
    font-family: var(--font-body-2);
    color: var(--color-accent-3);
    font-style: italic;
    font-weight: normal;
    font-size: var(--h6);
    padding-top: 0.5rem;
}
.site__aboutMe-photo img{
    flex: 1 2;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 150px;
    max-width: 150px;
}
.site__aboutMe-content{
    flex: 1 2;
    max-width: 480px;
    margin-right: 2rem;
    min-width: 390px
}
.site__aboutMe-content h1 {
    font-family: var(--font-intro);
    font-weight: 500;
    font-size: var(--h1);
    line-height: 120px;
    width: 100%;
    text-rendering: optimizeLegibility; 
    padding-bottom: 3rem;
}
.site__aboutMe-content h3{
    font-family: var(--font-body-2);
    color: var(--color-text);
    font-style: normal;
    font-weight: 300;
    line-height: var(--h3-lh);
    font-size: var(--h3);
    padding-bottom: 1.5rem;
}
 h6{
    font-family: var(--font-body-2);
    color: var(--color-links);
    font-style: normal;
    font-weight: normal;
    font-size: var(--h6);
    padding-bottom: 1.5rem;
}
.site__aboutMe-content p{
   font-family: var(--font-body-2);
    font-weight: 300;
    font-size: var(--p);
    color: var(--color-text-2);
    line-height: 25px;
    padding: .2rem 0;
    width: fit-content;
}
.site__aboutMe-photo .site__3col-groupA, .site__aboutMe-photo .site__3col-groupB, .site__aboutMe-photo .site__3col-groupC {
    width: min-content;
}


@media screen and (max-width: 1085px){
    .site__aboutMe-photo.site__3col{
        padding-top: 5rem;
    }
}
@media screen and (max-width: 1050px){
    
    .site__aboutMe-photo.site__3col{
        display: flex;
    }
    .site__aboutMe-photo .site__3col-groupA, .site__aboutMe-photo .site__3col-groupB, .site__aboutMe-photo .site__3col-groupC {
        width: unset;
    }
    .site__aboutMe-photo img{
        min-width: unset;
    }
}
 /* tablets */

 @media screen and (max-width: 990px) {
    
    /* .site__aboutMe-photo {
        max-width: 300px;
    } */
    .site__aboutMe-photo.site__3col{
        padding-top: unset;
    }
  
    
 }
 @media screen and (max-width: 700px) {
    .site__pages.section__padding{
        padding: 4rem;
    }
    .site__pages.section__margin{
        padding: 4rem;
    }
  }

  /* mobile */
  @media screen and (max-width: 550px) {
    .site__pages.section__padding{
        padding: 2rem 2rem;
    }
    .site__pages.section__margin{
      margin: 4rem 2rem;
  }
  .site__header-pages h1{
    font-size: var(--h3);
    /* letter-spacing: -0.2rem; */
    min-width: unset;
    margin-right: 1rem;
    /* line-height: 80px; */
  }
  /* #uxpage-title{
    font-size: 90px;
  } */
  
  .site__aboutMe-content{
    margin-right: unset;
    padding-top: 2rem;
    min-width: unset;

}
.site__aboutMe{
    flex-direction: column-reverse;
}
.site__aboutMe-content h1{
    font-size: 90px;
    padding-bottom: unset;
}
.site__aboutMe-photo img{
    padding-top: unset;

}
}