.site__blog-container_article{
    width: 100%;
    display: flex;
    flex-direction: row;
    filter: brightness(70%);
}
.site__blog-container_article:hover{
    transform: scale(1.01);
    filter: brightness(100%);
}
.site__blog-container_article-image{
    width: 100%;
    /* background: var( --color-accent); */
}
.site__blog-container_article-image img{
    width: 100%;
    /* height: 100%; */
    border-radius: var(--border-radius);
}

.site__blog-container_article-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    /* height: 100%; */
    position: absolute;
    width: 75%;
    

}
.site__blog-container_article-content p{
    font-family: var(--font-body-2);
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    padding-bottom: .5rem;
    letter-spacing: .1rem;
    text-transform: uppercase;
    color: var(--color-text-2);
    box-sizing: border-box;

}
.site__blog-container_article-content h3{
    font-family: var(--font-blog-title);
    font-weight: 400;
    font-size: clamp(1.5rem, 2vw, 2rem);
    line-height: clamp(1.5rem, 2.5vw, 2rem);
    color: var(--color-text);
    letter-spacing: 0.02rem;
    padding-bottom: 1rem;
}

.links-article:hover{
    color: var(--color-links);
}

@media screen and (max-width: 850px){
    .site__blog-container_article-content h3{
        line-height: 35px;
        font-size: 30px;
    }
    .site__blog-container_article-content{
        width: 40%;
    }
    .site__blog-container_article-content p{
        font-size: 20px;
        padding-bottom: 1rem;
        line-height: 34px;
    }
    .site__blog-container_article{
        filter: brightness(100%);
    }
}

@media screen and (max-width: 550px){
    .site__blog-container_article-content{
        padding: 1.5rem 0.5rem;
        width: 70%;
    }
    .site__blog-container_article-content h3{
        font-size: 24px;
        line-height: 28px;
        padding-left: 1rem;
    }
    .site__blog-container_article-content p{
        line-height: 20px;
        font-size: 14px;
        padding-left: 1rem;
        padding-bottom: 0.5rem;
    }

}