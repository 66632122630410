.site__features{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: none;
    flex-direction: row;
}

.site__features-heading img{
    width: 100%;
    height: auto;
    margin-top: 2rem;
}

.site__features-heading{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 10rem;
}

.site__features-heading h3{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: var(--h3);
    line-height: clamp(2.5rem, 2.5vw, 2rem);
    letter-spacing: 0.02rem;
    color: var(--solid-text);
}

.site__features-heading p{
    font-family: var(--font-body-2);
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 30px;
    margin-top: 2rem;
    color: var(--color-text);
}

.site__features-container{
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}


.site__features-container .site__features-container__feature{
    border-bottom: var(--color-accent-3) 1px solid;
    padding-bottom: 1rem;
}
.site__features-container .site__features-container__feature-title div:nth-child(1){
    display: none;
}
.site__features-container .site__features-container_feature-text h6{
    margin-top: unset;
}
.site__features .site__features-heading p{
    font-family: var(--font-body-2);
    font-weight: 300;
    font-size: var(--h6);
    line-height: 24px;
    color: var(--solid-text-3);
    max-width: 300px;
}
/* .site__features-container .site__features-container__feature h5{
    font-size: var(--h6);
} */


@media screen and (max-width: 990px) {
    .site__features{
        flex-direction: column;
        margin: unset;
    }

    .site__features-heading{
        margin: 0 0 2rem 0;
    }
}

@media screen and (max-width: 550px) {
    .site__features-heading h1{
        line-height: 50px;
        font-size: 46px;
    }
}