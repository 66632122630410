
.site__footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}

.site__footer-heading{
    width: 100%;
    margin-bottom: 3rem;
    border-bottom: 1px solid #323232;
    line-height: var(--h3);
    /* border-bottom: solid 1px var(--color-text-2); */
}
.site__footer-heading.site__2col{
    padding-top: unset;
    justify-content: space-between;
    align-items: center;

}
.site__footer-links_logo h1{
    padding-bottom: 1rem;
}

.site__footer-heading h3{
    font-size: var(--h3);
    font-family: var(--font-family);
    font-weight: 300;
    line-height: 75px;
    text-align: left;
    color: var(--solid-text);
}
/* .site__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid var(--color-general);
    text-align: center;
    margin-bottom: 10rem;
    cursor: pointer;
}
.site__footer-btn p{
    color: var(--color-general);
    box-sizing: border-box;
    font-family: var(--font-body);
    font-size: 18px;
    line-height: 21px;
    word-spacing: 2px;
} */

.site__footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
}
.links-footer:hover{
    color: var(--color-links);
}

.site__footer-links div{
    width: 250px;
    /* margin: 1rem; */
}
.site__footer-links_logo{
    display: flex;
    flex-direction: row;
    color: var(--solid-text);
    font-family: var(--font-intro);
    text-transform: uppercase;
    font-size: 20px;
    
}
.site__footer-links_logo svg{
    width: 70px;
    height: 100%;
}
.site__footer-links_logo img{
    width: 118px;
    height: 100%;
    margin-bottom: 1rem ;
}
.site__footer-links_logo p{
    font-family: var(--font-body);
    font-size: 12px;
    line-height: 14px;
    color: var(--color-general);
}
.site__footer-links_div{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.site__footer-links_div h5{
    font-family: var(--font-body);
    font-size: var(--h5);
    font-weight: 400;
    line-height: 16px;
    color: var(--solid-text);
    margin-bottom: 1rem;
   
}
.site__footer-links_div h6{
    font-family: var(--font-body-2);
    font-size: var(--h6);
    font-weight: 300;
    line-height: 10px;
    /* margin: 0.5rem 0; */
    cursor: pointer;
    color: var(--color-text);
}
.site__footer-links_div #section{
    color: var(--color-text-2);
}
.site__footer-links_div i, .site__footer-links_div img {
    margin-right: 1.2rem;
}
.site__footer-links_div img{
    width: 13px;
}
.site__footer-links:last-child{
    text-align: right;
}
.site__footer-copyright{
    margin-top: 2rem;
    text-align: left;
    width: 100%;
}
.site__footer-copyright p{
    font-family: var(--font-body-2);
    font-size: 10px;
    line-height: 14px;
    color: var(--color-accent-3);
}

.site__footer-links_div img, #kofi{
    margin-right: none;
}

@media  screen and (max-width: 850px){
    .site__footer-heading h1{
        font-size: 44px;
        line-height: 50px;
    }
}
@media  screen and (max-width: 550px){
    .site__footer-heading h1{
        font-size: 34px;
        line-height: 42px;
    }
    .site__footer-links div{
        margin: 1rem 0;
    }
    .site__footer-btn p{
        font-size: 14px;
        line-height: 20px;
    }
}
@media  screen and (max-width: 550px){
    /* .site__footer-heading h1{
        font-size: 50px;
        line-height: 61px;
    } */
    .site__footer-heading{
        padding-bottom: 1rem;
    }
    .site__footer-links_logo h1{
        padding-bottom: unset;
    }
    /* .site__footer-btn {
        margin-bottom: 5rem;
    } */
    .site__footer-links div {
        width: 150px;
    }
    
}