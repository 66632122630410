#recent-container{
    background-color: unset;
}


@media screen and (max-width: 550px){
    #recent-container.site__gallery-container_groupA{
        /* margin: unset; */
        padding: 3rem 2rem 0 2rem;
        align-items: flex-start;
        text-align: left;
    }
}