.site__header{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    /* margin-top: 5rem; */
    /* margin-bottom: 4rem; */
}

.site__header-content{
    flex: 1 2;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    text-align: left;
}

/* .site__header-pages .site__header-content{
    margin-right: 5rem;
} */
.site__header .site__2col, .site__header-pages .site__2col{
    align-items: center;
    padding-top: unset;
    /* justify-content: space-between; */
    flex-direction: column;
}

.site__header-content h1{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: var(--h1);
    /* line-height: 120px; */
    letter-spacing: -0.1rem;
    width: 100%;
    text-rendering: optimizeLegibility;
    text-align: center; 
}
.site__header-content h3{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: var(--h3);
    min-width: 700px;
    line-height: 120px;
    width: 100%;
    text-rendering: optimizeLegibility; 
    color: var(--solid-text);
}
.site__intro-content h5{
    font-family: var(--font-body-2);
    font-style: normal;
    font-weight: 300;
    font-size: var(--p);
    line-height: 25px;
    color: var(--color-text-2);
    padding: 2rem 0 2rem 0;
    max-width: 420px;
    text-align: center; 
}

.site__header-content p{
    font-family: var(--font-body-2);
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    color: var(--color-text-2);
    line-height: 25px;
    padding: 2rem 0;
    width: fit-content;
}
.site__header-content .site__2col{
    justify-content: space-between;
}

.site__header-image img{
    max-height: 550px;
    max-width: 735px;
}
.site__header-image p{
    font-family: var(--font-family);
    /* color: #323232; */
    color: var(--color-text-2);
    font-size: var(--h6);
    padding-bottom: 0.5rem;
    
}
#hPhoto{
    min-height: 530px;
    max-height: 530px;
    width: unset;
}

.site__header-image svg{
    color: var(--color-text-2);
    display: inline-flex;
}
.site__header-image .site__2col{
    padding-top: unset;
    justify-content: space-between;
}
.site__header-image .site__2col .site__2col-groupA p{
    text-transform: uppercase;
    font-size: var(--h6);
    line-height: var(--h6);
}
.site__header-image .site__2col .site__2col-groupB p{
    font-family: var(--font-family);
    font-size: var(--h6);
    line-height: var(--h6);
}
.site__header h2{
    font-family: var(--font-body-2);
    color: var(--color-subtext);
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
}
.site__header-image h4{
    font-family: var(--font-body-2);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: var(--color-subtext);
    text-transform: uppercase;
    padding-bottom: .5rem;
    min-width: 150px;
}
.project-btn-container{
    display: flex;
    justify-content: flex-start;
    /* margin: 36px 0 0 0; */
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-start;
    /* width: 100%; */
    padding-top: 1rem;
}
.project-btn-container .project-btn:nth-child(1){
    margin-right: 1rem;
    margin-bottom: 2rem;
}
.project-btn-container .project-btn{
    padding: unset;
}

/* .site__header-content__input{
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
} */
/* .site__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    background: var(--color-footer);
    
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
} */
/* .site__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    background: #ff4820;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
} */


@media screen and (max-width: 1050px){
    .site__header .site__2col, .site__header-pages .site__2col{
        /* flex-direction: column-reverse; */
        padding-top: unset;
    }
    .site__header{
        margin-top: 0;
        margin-bottom: 0;
    }
    .site__header-image{
        margin-bottom: 2rem;
    }
    #home .site__header-content h1{
        min-width: unset;
        
    }
    /* .site__header-content h1{
        line-height: 100px;
    } */
}
@media screen and (max-width: 650px){

    .site__header p{
        font-size: 16px;
        line-height: 24px;
    }
    .site__header-content__input input,
    .site__header-content__input button{
        line-height: 24px;
        font-size: 16px;
    }
}
@media screen and (max-width: 490px){
    
    .site__header.section__padding{
        padding: 2rem;
    }
    #home .site__header-content h1 {
        line-height: 80px;
    }
    .site__header-pages .site__header-content {
        margin-right: unset;
    }
    .site__header h3{
        font-size: 14px;
        max-width: 315px;
        line-height: 20px;
        margin: unset;
    }
    .site__intro-content{
        margin: 1rem 0;
    }
    .site__header-image{
        flex-direction: row;
        max-width: 350px;
    }
    .crop{
        height: 250px;
        overflow: hidden;
    }
    .site__header p{
        font-size: 12px;
    }
    .site__header-content__input input,
    .site__header-content__input button{
        line-height: 16px;
        font-size: 12px;
    }
    .project-btn-container{
        margin: 18px 0 0 0;
    }
    .project-btn-container .project-btn:nth-child(1){
        margin-bottom: 2.5rem;
        margin-right: unset;
    }
}

/* KEYFRAMES */
 
 @-webkit-keyframes rotate-scale-up {
    0% {
      -webkit-transform: scale(1) rotateZ(0);
              transform: scale(1) rotateZ(0);
    }
    50% {
      -webkit-transform: scale(2) rotateZ(180deg);
              transform: scale(2) rotateZ(180deg);
    }
    100% {
      -webkit-transform: scale(1) rotateZ(360deg);
              transform: scale(1) rotateZ(360deg);
    }
  }
  @keyframes rotate-scale-up {
    0% {
      -webkit-transform: scale(1) rotateZ(0);
              transform: scale(1) rotateZ(0);
    }
    5% {
        -webkit-transform: scale(2) rotateZ(180deg);
        transform: scale(2) rotateZ(180deg);
    }
    10% {
      -webkit-transform: scale(1) rotateZ(360deg);
              transform: scale(1) rotateZ(360deg);
    }
    100% {
        -webkit-transform: scale(1) rotateZ(360deg);
                transform: scale(1) rotateZ(360deg);
      }
  } 