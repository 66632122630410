.site__masonry-content h2{
    font-size: var(--h2);
    line-height: clamp(2.5rem, 2.5vw, 2rem);
    font-weight: 300;
    font-family: var(--font-family);
    max-width: 510px;
    color: white;
}
.site__masonry-content h6{
    font-family: var(--font-body-2);
    font-weight: normal;
    font-weight: 300;
    font-size: var(--h6);
    line-height: 15px;
    padding-top: 1rem;
    letter-spacing: var(--letter-spacing);
    text-transform: uppercase;
    color: var(--color-text-2);
    box-sizing: border-box;
}

.site__masonryHome-container .site__masonry{
    padding:unset;
    margin-top: 5rem;
}
.site__masonryHome-container .site__2col{
    padding-top: unset;
    justify-content: space-between;
}