

/* .site__blog{
    display: flex;
    flex-direction: row-reverse;
} */
.site__blog-heading{
    width: 100%;
    text-align: center;
    color: var( --solid-text);
}
.site__blog-heading h1{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: clamp(3.5rem, 3vw, 3rem);
    line-height: clamp(3.5rem, 3.5vw, 3rem);
    letter-spacing: 0.02rem;
}
.site__blog-heading svg{
    color: white;
}
.site__blog-container_groupA{
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
    margin: 4rem 0 0 0;
    padding: 5rem 4rem 0 4rem;
    border-radius: var(--border-radius);
    text-align: center;
}
.site__blog-container_groupA h3{
     font-family: var(--font-blog-title);
    font-weight: 400;
    font-size: clamp(1.5rem, 2vw, 2rem);
    line-height: clamp(1.5rem, 2.5vw, 2rem);
    color: var(--color-text);
    letter-spacing: 0.02rem;
}
.site__blog-container_groupA p{
    font-family: var(--font-body-2);
    font-weight: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 25px;
    padding-top: 1rem;
    letter-spacing: var(--letter-spacing);
    text-transform: uppercase;
    color: var(--color-text-2);
    box-sizing: border-box;
}

.site__blog-container_groupB{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.site__blog-container_groupC{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-top: 2rem;
}

.site__blog.section__padding{
    padding: 6rem 6rem 4rem 6rem;
}




@media screen and (max-width: 990px){
    .site__blog-container{
        flex-direction: column;
    }
    
    .site__blog-container_groupA{
        margin: 2rem 0 0 0;
    }
    .site__blog-container_groupA .site__blog-container_article{
        width: 48%;
    }
    .site__blog-container_groupA .site__blog-container_article-image{
        height: 250px;
    }
}
@media screen and (max-width: 850px){
    .site__blog-container_groupB{
        grid-template-columns: repeat(2,1fr);
    }
    
    .site__blog-container_groupA .site__blog-container_article{
        width: 100%;
    }
}
@media screen and (max-width: 550px){
   
    .site__blog-container_groupB{
        grid-template-columns: repeat(2,1fr);
    }
    .site__blog-heading{
        padding: 0 2rem;
    }
    .site__blog-heading h1{
        font-size: clamp(2rem, 2vw, 2rem);
        line-height: clamp(2rem, 2.5vw, 2rem);
    }
}