*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    
}

body {
    background: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;
}

.site__header-img-BG{
  /* background-image: linear-gradient(0deg, rgba(18, 21, 23, 1), rgba(18, 21, 23, 0.2)), url("./assets/home-bg.webp"); */
  background-repeat: no-repeat;
  height: auto;
  background-size: cover; 
}

.gradient__bg
{
  /* ff 3.6+ */
  background:-moz-radial-gradient(ellipse at 8% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);

  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(ellipse at 8% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);

  /* opera 11.10+ */
  background:-o-radial-gradient(ellipse at 8% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);

  /* ie 10+ */
  background:-ms-radial-gradient(ellipse at 8% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);

  /* global 92%+ browsers support */
  background:radial-gradient(ellipse at 8% 0%, rgba(37, 43, 50, 1) 0%, rgba(24, 28, 32, 1) 63%);
}

.gradient__text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.solid__text{
  color: var(--solid-text);
}
.solid__text-2{
  color: var(--solid-text-2);
}

.link:hover{
  /* color: var(--color-links); */
  background-color: var(--color-links);
  background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding{
    padding: 6rem 6rem;
}
.section__margin{
    margin: 4rem 6rem;
}

.site__container-bg{
  /* background: Black; */
  background-position: inherit;
  background-size: cover;
  padding: 1rem 0rem;
}
.site__intro-containter{
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  /* padding: 0 2rem;
  margin: 0 6rem; */
}

.site__title h2{
    font-size: var(--h2);
    /* line-height: clamp(2.5rem, 2.5vw, 2rem); */
    line-height: clamp(3.5rem, 3.5vw, 3rem);
    font-weight: 300;
    font-family: var(--font-family);
    max-width: 510px;
    color: white;
    padding-bottom: 1rem;
}
.site__title p{
    font-family: var(--font-body-2);
    font-weight: 300;
    font-size: var(--p);
    color: var(--color-text-2);
    line-height: 25px;
    padding: .2rem 0;
    width: fit-content;
}



/* .site__masonryHome-title{
  margin: 2rem 0 5rem 0;
}
.site__masonryHome-title h2{
  font-family: var(--font-family);
  font-weight: 300;
  font-size: var(--h2);
  line-height: clamp(3.5rem, 3.5vw, 3rem);
  letter-spacing: 0.02rem;
  color: var(--solid-text);
}
.site__masonryHome-title h6{
  font-family: var(--font-body-2);
    font-weight: normal;
    font-weight: 300;
    font-size: var(--h6);
    line-height: 25px;
    padding-top: 1rem;
    letter-spacing: var(--letter-spacing);
    text-transform: uppercase;
    color: var(--color-text-2);
    box-sizing: border-box;
} */


.site__2col-img{
  display: flex;
  flex-direction: row;
}
.site__2col-img img{
  width: 100%;
}
.site__2col-img-groupA{
  flex: 0.75;
  margin-right: 0.2rem;
}
.site__2col-img-groupB{
  flex: 1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0.2rem;
}
.site__2col{
  /* padding-top: 4rem; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}
.site__2col img, .site__3col img{
  width: 100%;
}
.site__2col-groupA{
  margin-right: 0.2rem;
}
.site__2col-groupA p, .site__2col-groupB p{
    font-family: var(--font-body-2);
    font-weight: 300;
    font-size: var(--p);
    color: var(--color-text-2);
    line-height: 25px;
    max-width: 500px;
}
.site__3col{
  /* padding-top: 4rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.site__3col-groupA, .site__3col-groupB{
  margin-right: 0.2rem;
}
.site__3col-img-groupA{
  flex: 1;
  margin-right: 2rem;
}
.site__3col-img-groupB{
  flex: 1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
}

/* SVG CSS properties */
.svg__container{
  display: inline-flex;
  align-self: center;
}
.svg__container svg, .svg__container img{
  height: 1em;
  width: 1em;
}
.svg__container.basline svg{
  top: 0.125em;
  position: relative;
}
.svg__icon{
    height: auto;
    width: 20px;
    display: inline;
    position: relative;
    margin: unset;
}

  /* Animations — Scroll Text */
  #marquee-container {
    max-width: 100%;
    overflow: hidden;
    /* border-top: 1px solid var(--color-text-2); */
    /* border-bottom: 1px solid var(--color-text-2); */
    padding: 1.2rem 0;
  }
  
  #marquee {

    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    
    -moz-animation: my-animation 35s linear infinite;
    -webkit-animation: my-animation 35s linear infinite;
    animation: my-animation 35s linear infinite;
  }

  .view-mobile{
    display: none;
  }

  .site__no-hover:hover{
    color: none;
  }
  
@media screen and (max-width: 900px){
  .view-desktop{
    display: none;
  }
  .view-mobile{
    display: block;
  }
}
  
  /* tablets */
  @media screen and (max-width: 700px) {
    .section__padding{
        padding: 4rem;
    }
    .section__margin{
        padding: 4rem;
    }
    .site__header-img-BG{
      height: unset;
    }
    .site__masonryHome-title{
      margin: 2rem 0;
    }
    
  }

  /* mobile */
  @media screen and (max-width: 550px) {
    .section__padding{
        padding: 3rem 2rem;
    }
    .section__margin{
      margin: 4rem 2rem;
  }
  .site__blog.section__padding, .site__gallery.section__padding{
    padding: 2rem 2rem;
  }
  #marquee-container{
    display: none;
  }
  .site__header-img-BG{
    height: unset;
    background-position: unset;
  }
  
  .site__masonryHome-title{
    margin: 2rem 0;
  }
  .site__intro-content h5{
    padding: unset;
  }
  }